import {
  ChallengeScoreResult,
  ChallengeSpecialtyScoreResult,
  GlobalChallengeScoreResult,
} from '../api-results/challenge-score.api-result';
import { Specialty } from './specialty.entity';
import { Student } from './student.entity';

export class GlobalChallengeScore {
  challengeScores: ChallengeScore;
  challengeSpecialtyScores: ChallengeSpecialtyScore[];

  constructor(result: GlobalChallengeScoreResult) {
    if (result.challengeScores) this.challengeScores = new ChallengeScore(result.challengeScores);
    if (result.challengeSpecialtyScores)
      this.challengeSpecialtyScores = result.challengeSpecialtyScores.map(
        (elt) => new ChallengeSpecialtyScore(elt)
      );
  }
}

export class ChallengeScore {
  defeats: number;
  victories: number;
  successRate: number;
  score: number;
  rank: number;
  student: Student;

  constructor(result: ChallengeScoreResult) {
    this.defeats = result.defeats;
    this.victories = result.victories;
    this.successRate = result.successRate;
    this.score = result.score;
    this.rank = result.myRank ? result.myRank : result.studentRank;
    if (result.student) this.student = new Student(result.student);
  }
}

export class ChallengeSpecialtyScore {
  defeats: number;
  victories: number;
  successRate: number;
  score: number;
  rank: number;
  specialty: Specialty;
  student: Student;

  constructor(result: ChallengeSpecialtyScoreResult) {
    this.defeats = result.defeats;
    this.victories = result.victories;
    this.successRate = result.successRate;
    this.score = result.score;
    this.rank = result.mySpecialtyRank ? result.mySpecialtyRank : result.studentSpecialtyRank;
    if (result.specialty) this.specialty = new Specialty(result.specialty);
    if (result.student) this.student = new Student(result.student);
  }

  toResult(): ChallengeSpecialtyScoreResult {
    return {
      defeats: this.defeats,
      victories: this.victories,
      successRate: this.successRate,
      score: this.score,
      studentSpecialtyRank: this.rank,
      mySpecialtyRank: this.rank,
      specialty: this.specialty?.toResult(),
      student: this.student?.toResult(),
    };
  }
}
