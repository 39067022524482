import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { SessionEcosActionResult } from '../api-results/session-ecos-action.api-result';
import { EcosActionType } from '../enums/ecos.enum';
import moment from 'moment';
import { EcosAction, EcosSection } from './ecos-correction-grid.entity';

import { findSessionToRecover } from '../../../core/state/auth-state/auth.actions';
import { findIndex } from 'rxjs';
import { ActionButton } from '../interfaces/action-button.interface';

export class SessionEcosAction {
  grade: number;
  createdAt: Date;
  answerContent: string;
  answerType: EcosActionType;
  ecosActionId: string;
  patientNegativeResponse: string;

  ecosAction: EcosAction;
  alreadyDone: boolean;

  actionButtons: ActionButton[];

  constructor(result: SessionEcosActionResult) {
    this.grade = result.grade;
    if (result.createdAt) this.createdAt = moment(result.createdAt).toDate();
    this.answerContent = result.answerContent;
    this.answerType = result.answerType;
    this.ecosActionId = result.ecosActionId;
    this.patientNegativeResponse = result.patientNegativeResponse;
  }

  toResult(): SessionEcosActionResult {
    return {
      grade: this.grade,
      createdAt: this.createdAt ? moment(this.createdAt).format(API_DATETIME_FORMAT) : null,
      answerContent: this.answerContent,
      answerType: this.answerType,
      ecosActionId: this.ecosActionId,
      patientNegativeResponse: this.patientNegativeResponse,
    };
  }

  static buildEcosActionsFromCorrectionGrid(
    sessionEcosActions: SessionEcosAction[],
    ecosActions: EcosAction[]
  ) {
    sessionEcosActions.forEach((sessionEcosAction, idx) => {
      if (sessionEcosAction.ecosActionId) {
        sessionEcosAction.ecosAction = ecosActions.find(
          (ecosAction) => ecosAction.id === sessionEcosAction.ecosActionId
        );

        const firstSEAOccurence = sessionEcosActions.findIndex(
          (currSEA) => sessionEcosAction.ecosActionId === currSEA.ecosActionId
        );
        if (firstSEAOccurence < idx) {
          sessionEcosAction.alreadyDone = true;
        }
      }
    });
  }
}

export interface StudentEcosAction {
  answerType: EcosActionType;
}
